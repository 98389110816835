const useTools = () => {
	const scrollToSection = (sectionId, block = 'center') => {
		const element = document.getElementById(sectionId)
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block, inline: 'nearest' })
			// window.scrollTo({
			//     top: element.offsetTop,
			//     behavior: 'smooth'
			// })
		}
	}
	const download = (response: any, name: string) => {
		const url = window.URL.createObjectURL(new Blob([response._data.data], { type: response._data.data }))
		const link = document.createElement('a')
		const contentDisposition = response.headers['content-disposition']

		let fileName = name || 'unknown'
		if (contentDisposition) {
			let fileNameMatch = contentDisposition.match(/filename="(.+)"/)
			if (!fileNameMatch) {
				fileNameMatch = contentDisposition.match(/filename=(.+)/)
				if (fileNameMatch.length === 2) {
					fileName = fileNameMatch[1]
				}
			} else if (fileNameMatch.length === 2) {
				fileName = fileNameMatch[1]
			}
		}
		link.href = url
		link.setAttribute('download', fileName)
		document.body.appendChild(link)

		link.click()
		link.remove()
		window.URL.revokeObjectURL(url)
	}
	return {
		scrollToSection,
		download
	}
}

export default useTools
